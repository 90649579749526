@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: rgba(0,0,0,.65);
  background-color: #F3F6FA;
}

.app,
.app__content {
  height: 100vh;
}
/* .ant-layout-header,
.ant-menu {
  height: 54px;
  line-height: 54px;
  background-color: white;
} */
/* .ant-menu-item {
  padding: 0 11px;
} */
.ant-layout-header {
  background-color: white;
}
.ant-layout-content {
  background-color: #F3F6FA;
}

.logo {
  float: left;
width: 56px;
height: 42px;
margin: 10px 0;
background: transparent url('../public/logo.ico') no-repeat;
background-size: contain;
}
.navigator {
  min-width: 120px;
  display: flex;
  float: left;
  .nav-item {
    margin: 0 8px;
  }
}
.user {
  float: right;
}

.card-rule-btns button{
  margin-left:8px;
}

.ant-form-item {
  &-explain, &-extra {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ProManger{
  background:#fff;
}

.standardTableArea{
  width:100%;
  /* // height:calc(100vh - 87px);
  // padding:0 10px;
  background-color: #fff; */
}

.standardOpe {
  width:100%;
  height:100%;
  /* // background:#fff; */
  /* .opeForm{
    margin:15px 0 10px;
    padding:10px;
    background-color: #fff;
  }

  .standardTableArea{
    width:100%;
    height:calc(100vh - 87px);
    padding:10px;
    background-color: #fff;
    .btns{
      text-align: right;
      button{
        margin-left:8px;
      }
      
    }
    .table{
      margin-top:8px;
    }
  } */
}

.ant-modal-header{
  background:#4B74FF;
}

.info{
  display: inline-block;
  margin-left:8px;
  color: #857e7e;
}

.viewModal_ {
  overflow:hidden !important;
  /* div.ant-modal{
    // position:absolute;
    // left:0;
    top:0 !important;
    // width:100vw;
    // height:100% !important;
    div.ant-modal-content{
      width:100%;
      height:calc(100vh - 32px);
      div.ant-modal-body{
        height:calc(100vh - 114px);
        overflow-y:auto;
      }
    }

  }
  h4{
    font-weight: bold;
  }
  p{
    text-indent:1rem;
  } */
  
}

.noGreen{
  color:rgba(0, 0, 0, 0.85);
}

.operation{
  text-align: center;
  /* a{
    margin:0 4px;
  } */
}


.Modal2Title{
  font-weight: bold;
  font-size: 14px;
}

.ModalTitle{
  font-weight: bold;
  font-size:16px;
}

#cover {
  transition: 2s ease-out 1s;
  
}

.cover {
  width: 10px;
}

.cover-active {
  width: 320px;
}
@primary-color: #2688FF;@background-color-light: #FAFAFA;@table-padding-vertical: 4px;@table-padding-horizontal: 8px;@input-height-base: 32px;@form-item-margin-bottom: 8px;@input-bg: transparent;